@import '/styles/utils/mixins';

.root {
  position: relative;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  border: 1px solid var(--gray-300);
  background-color: var(--main-white);

  @include mobile {
    width: 100%;
    height: 56px;
  }

  @include desktop {
    width: 100%;
    height: 70px;
  }

  &_error {
    border: 1px solid var(--status-error);
  }

  &_clicked {
    border: 1px solid var(--gray-300);
    background: var(--main-white);
    box-shadow: 0px 0px 0px 4px #f2f4f6;
  }
}

.search {
  display: flex;
  align-items: center;
  width: 85%;
}

.icon {
  display: flex;
  align-items: center;

  @include mobile {
    margin: 16px 8px 16px 16px;
  }

  @include desktop {
    margin: 24px 12px 24px 24px;
  }
}

.input {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  color: var(--main-black);

  &::placeholder {
    color: var(--gray-700);
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.input:focus::-webkit-input-placeholder {
  color: transparent;
}

.input:focus::-moz-placeholder {
  color: transparent;
}

.input:focus:-ms-input-placeholder {
  color: transparent;
}

.input:focus::placeholder {
  color: transparent;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 8px 8px 8px 0px;
  color: var(--main-white);
  background-color: var(--main-blue);

  @include mobile {
    width: 40px;
    height: 40px;
  }

  @include desktop {
    width: 56px;
    height: 56px;
  }
}

.error {
  position: absolute;
  inset: 100% 0 auto 0;
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
  color: var(--status-error);
}