@import '../../../styles/utils/mixins';
.title,
.titleAutopostingVK {
  display: none;

  @include tablet {
    display: block;
    margin-bottom: 16px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #888888;
  }

  @include desktop {
    margin-bottom: 11px;
  }
}

.titleAutopostingVK {

  @include desktop {
    margin-bottom: -9px;
  }
}

.scrollable {
  margin: 0 calc(0px - var(--base-mobile-wrapper-padding));
  padding: 0 var(--base-mobile-wrapper-padding);
  overflow: auto;
  scrollbar-width: none;

  &_brazil {
    opacity: 0.5;
  }

  @include tablet {
    margin: 0 calc(0px - var(--base-desktop-wrapper-padding));
    padding: 0 var(--base-desktop-wrapper-padding);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__test {
    margin-bottom: 64px;

    @include tablet {
      margin-bottom: 72px;
    }
  }
}

.list {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  min-width: fit-content;

  @include tablet {
    gap: 43px;
  }

  @include desktop {
    justify-content: center;
    gap: 70px;

    &_fullWidth {
      justify-content: space-between;
    }
  }
}

.img {
  display: block;
  width: 115px;
  height: 51px;
  object-fit: contain;
  filter: grayscale(100%);

  @include tablet {
    height: 57px;
  }

  @include desktop {
    width: 150px;
    height: 72px;
  }
}
