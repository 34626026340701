@import '/styles/utils/mixins';

.hero {
  position: relative;
  box-sizing: border-box;
  background-color: var(--background-color);
  overflow: hidden;

  &__shortPage {
    @include desktop {
      @include short-hero-page {
        height: 100dvh;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 144px;
    top: -382px;
    width: 877px;
    height: 877px;
    background: linear-gradient(
      101.09deg,
      #ffffff 9.19%,
      rgba(255, 255, 255, 0) 51.26%
    );
    border-radius: 100%;

    @include tablet {
      width: 1786px;
      height: 1786px;
      left: 243px;
      top: -956px;
    }

    @include desktop {
      width: 1877px;
      height: 1877px;
      left: 537px;
      top: -1136px;
    }

    @include big-desktop {
      width: 2165px;
      height: 2165px;
      left: 788px;
      top: -1325px;
    }
  }
}

.heroSecondary {
  margin-bottom: 30px;
  padding-top: calc(var(--base-header-height) + 64px);
  padding-bottom: 64px;
  overflow: hidden;
  background-color: var(--background-color);

  &_smallPadding {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &_noPaddingBottom {
    padding-bottom: 0;
  }

  @include tablet {
    padding-top: calc(var(--base-header-desktop-height) + 100px);
    padding-bottom: 100px;

    &_smallPadding {
      padding-top: 130px;
      padding-bottom: 130px;
    }

    &_noPaddingBottom {
      padding-bottom: 0;
    }
  }

  @include desktop {
    margin-bottom: -30px; /*For image to be visible under rounded corners of brand section*/
    padding-top: calc(var(--base-header-desktop-height) + 96px);
    padding-bottom: 140px;

    &_smallPadding {
      padding-top: 130px;
      padding-bottom: 130px;
    }

    &_noPaddingBottom {
      padding-bottom: 0;
    }
  }
}

.hero__wrapper {
  position: relative;
  min-height: 62vh;
  padding-top: calc(var(--base-header-height) + 64px);
  box-sizing: border-box;
  z-index: 1;

  @include tablet {
    padding-top: calc(var(--base-header-desktop-height) + 80px);
  }

  @include desktop {
    padding-top: calc(var(--base-header-desktop-height) + 69px);
    display: grid;
    min-height: 800px;
  }

  &_home {
    padding-top: calc(var(--base-header-height) + 64px);

    @include tablet {
      padding-top: calc(var(--base-header-desktop-height) + 80px);
    }

    @include desktop {
      padding-top: calc(var(--base-header-desktop-height) + 85px);
    }

    @include big-desktop {
      padding-top: calc(var(--base-header-desktop-height) + 128px);
    }
  }
}

.hero__secondaryWrapper {
  position: relative;
  // max-width: 1360px;
}

.hero__content {
  position: relative;
  z-index: 1;

  @include desktop {
    max-width: 830px;
  }

  &_longTitle {
    @include desktop {
      max-width: 910px;
    }
  }

  &_home {
    @include tablet {
      width: 558px;
    }

    @include big-desktop {
      width: 716px;
    }
  }

  &_about {
    .hero__title {
      margin-bottom: 16px;
      font-size: 2rem;
      font-weight: 600;

      @include tablet {
        font-size: 3rem;
      }

      @include desktop {
        font-size: 3.5rem;
      }
    }

    @include desktop {
      max-width: 623px;

      .hero__text {
        max-width: 623px;
      }
    }
  }

  &_directInstagram {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 830px;
    }
  }

  &_auditInstagram {
    @include big-desktop {
      max-width: 750px;
    }
  }

  &_accountStats {
    @include desktop {
      max-width: 651px;
    }

    @include big-desktop {
      max-width: 830px;
    }
  }

  &_instagramAnalytics {
    @include desktop {
      max-width: 690px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_vkAnalytics {
    @include desktop {
      max-width: 690px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_reports {
    @include desktop {
      max-width: 615px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_messages {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 852px;
    }

    .hero__text {
      @include desktop {
        max-width: 437px;
      }
    }
  }

  &_downloadCommentsInstagram {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_autoposting {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_partners {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_auditInstagram {
    @include desktop {
      max-width: 658px;
    }

    @include big-desktop {
      max-width: 852px;
    }

    .hero__text {
      @include desktop {
        max-width: 490px;
      }
    }
  }

  &_igCompetitors {
    @include desktop {
      max-width: 630px;
      font-size: 48px;
    }

    @include big-desktop {
      max-width: 852px;
    }
  }

  &_services {
    @include desktop {
      max-width: 900px;
    }
  }

  &_contacts {
    .hero__text {
      max-width: 623px;
    }
  }
}

.hero__section__main {
  margin-bottom: 80px;
  max-width: 569px;
  z-index: 1;

  @include desktop {
    margin-bottom: 0;
  }

  @include big-desktop {
    max-width: 754px;
  }
}

.hero__title {
  max-width: 600px;
  margin-bottom: 8px;

  @include tablet {
    margin-bottom: 16px;
  }

  @include desktop {
    font-size: 48px;
  }

  @include big-desktop {
    max-width: 900px;
    font-size: 56px;
  }

  &_long {
    max-width: 760px;

    @include big-desktop {
      max-width: 910px;
    }
  }

  &_home,
  &_homeBr {
    line-height: 44px;
    font-size: 32px;
    font-weight: 600;

    color: var(--primary-text-color);

    @include tablet {
      line-height: 72px;
      font-size: 48px;
    }

    @include desktop {
      line-height: 64px;
      font-size: 48px;
    }

    @include big-desktop {
      line-height: 89px;
      font-size: 56px;
    }
  }

  &_homeBr {
    @include desktop {
      font-size: 50px;
    }
  }

  &_autoposting,
  &_autoposting-vk {
    max-width: 560px;

    @include mobile {
      width: 300px;
    }

    @include tablet {
      width: 460px;
    }

    @include big-desktop {
      width: 520px;
    }

    &::before {
      content: url(/images/autoposting-title-underline.svg);
      position: absolute;
      margin-top: 20px;
      width: 300px;

      @include mobile {
        width: 200px;
        margin-top: 12px;
      }

      @include tablet {
        width: 300px;
        margin-top: 15px;
      }

      @include big-desktop {
        width: 468px;
        margin-top: 24px;
      }
    }
  }

  &_services {
    max-width: 900px;
    font-size: 56px;
  }
}

.hero__text {
  margin-bottom: 32px;
  max-width: 523px;
  color: var(--secondary-text-color);
  line-height: 1.875;
  white-space: pre-line;

  @include tablet {
    margin-bottom: 52px;
    font-size: 18px;
  }

  &_home {
    max-width: unset;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;

    @include tablet {
      margin-bottom: 40px;
      line-height: 32px;
      font-size: 18px;
    }
  }

  &_autoposting {
    @include mobile {
      width: 300px;
    }

    @include tablet {
      width: 520px;
    }
  }

  &_services {
    max-width: 900px;
  }
}

.hero__secondaryText {
  margin-bottom: 32px;

  @include tablet {
    max-width: 523px;
    margin-bottom: 48px;
  }

  @include desktop {
    max-width: 615px;
  }
}

.hero__img {
  display: none;
  width: auto;
  height: auto;

  @include desktop {
    display: block;
    position: absolute;
    right: 80px;
    bottom: -155px;
    min-width: 100px;
  }

  &_home {
    display: block;
    margin-left: -28px;
    max-height: 353px;
    margin-bottom: 10px;
    max-width: 1140px;

    @include tablet {
      margin-left: -48px;
      margin-bottom: -3px;
      max-height: unset;
    }

    @include desktop {
      left: calc(var(--base-desktop-wrapper-padding) + 647px);
      top: calc(var(--base-header-desktop-height) + 42px);
      margin-bottom: unset;
    }

    @include big-desktop {
      left: calc(var(--base-desktop-wrapper-padding) + 827px);
      top: calc(var(--base-header-desktop-height) + 50px);
    }
  }

  &_accountStats {
    @include desktop {
      right: -73px;
      height: 412px;
      width: auto;
    }

    @include big-desktop {
      height: 485px;
      right: -100px;
    }
  }

  &_instagramAnalytics {
    @include desktop {
      max-width: 670px;
      right: 10px;
      bottom: auto;
      top: 0;
    }

    @include big-desktop {
      right: -50px;
      bottom: -70px;
      top: auto;
    }
  }

  &_vkAnalytics {
    @include desktop {
      margin-right: 120px;
      bottom: 40px;
    }

    @include big-desktop {
      scale: 1.4;
      margin-right: 240px;
      bottom: 60px;
    }
  }

  &_statistika-akkaunta-tik-tok {
    @include desktop {
      margin-right: 180px;
      bottom: 40px;
    }

    @include big-desktop {
      scale: 1.4;
      margin-right: 180px;
      bottom: 40px;
    }
  }

  &_statistika-telegram {
    @include desktop {
      margin-right: 50px;
      bottom: -120px;
    }

    @include big-desktop {
      scale: 1.4;
      margin-right: 50px;
      bottom: -120px;
    }
  }

  &_statistika-odnoklassniki {
    @include desktop {
      margin-right: 50px;
      bottom: 0;
    }

    @include big-desktop {
      margin-right: 50px;
      bottom: 0;
    }
  }

  &_fbAnalytics {
    @include desktop {
      margin-right: 120px;
      bottom: 40px;
    }

    @include big-desktop {
      scale: 1.4;
      margin-right: 180px;
      bottom: -50px;
    }
  }

  &_monitoring {
    @include desktop {
      width: 526px;
      height: 476px;
      right: 32px;
    }
  }

  &_monitoringBr {
    @include desktop {
      width: 526px;
      height: 476px;
      right: 32px;
      bottom: -40px;
    }
  }

  &_autoposting,
  &_autoposting-vk {
    display: block;
    margin-left: -28px;
    max-height: 353px;
    margin-bottom: 10px;

    @include mobile {
      scale: 1.3;
      margin-left: 70px;
      margin-top: 40px;
    }

    @include tablet {
      scale: 2.2;
      margin-top: 170px;
      margin-left: 300px;
    }

    @include desktop {
      left: calc(var(--base-desktop-wrapper-padding) + 647px);
      margin-bottom: 450px;
    }
  }


  &_autoposting-vkEs {
    @include desktop {
      margin-bottom: 390px;
    }
  }

  &_reports {
    @include desktop {
      width: 551px;
      height: 426px;
      right: 17px;
    }
  }

  &_bloggersCheck {
    @include desktop {
      width: 436px;
      height: 426px;
      right: 66px;
    }
  }

  &_kpi {
    @include desktop {
      width: 426px;
      height: 426px;
    }
  }

  &_directInstagram {
    @include desktop {
      bottom: -60px;
    }
  }

  &_storiesStats {
    @include desktop {
      bottom: -125px;
    }
  }

  &_messages {
    @include desktop {
      bottom: -125px;
    }
  }

  &_downloadCommentsInstagram {
    @include desktop {
      bottom: -125px;
    }
  }

  &_auditInstagram {
    @include desktop {
      bottom: -115px;
    }
  }

  &_igCompetitors {
    @include desktop {
      bottom: -35px;
    }
  }

  &_contacts {
    @include desktop {
      width: 500px;
      bottom: -130px;
    }
  }
}

.hero__actions {
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;

  @include tablet {
    flex-direction: row;
    gap: 40px;
  }
}

.hero__extraMargin {
  margin-bottom: 121px;

  @include tablet {
    margin-bottom: 139px;
  }

  @include desktop {
    margin-bottom: 134px;
  }
}

.hero__secondaryActions {
  margin: 0;
}

.hero__action {
  box-sizing: border-box;
}

.hero__secondaryAction {
  padding: 0;

  &_autoposting {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 60px;
    }

    @include tablet {
      flex-direction: row;
      justify-content: flex-start;
    }

    @include desktop {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_autoposting-vk {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 60px;
    }

    @include tablet {
      flex-direction: row;
      justify-content: flex-start;
    }

    @include desktop {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.hero__action__desktop {
  display: none;

  @include tablet {
    display: block;
  }
}

.hero__button {
  padding: 0 40px;
  min-width: unset;

  @include desktop {
    &:hover {
      .hero__tooltip {
        display: block;
      }
    }
  }
}

.hero__demoLink {
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color);

  @include tablet {
    line-height: 16px;
    font-size: 16px;
  }

  @include desktop {
    margin-bottom: unset;
  }
}

.hero__linkToScroll {
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary-color);

  @include mobile {
    margin-top: 20px;
    line-height: 14px;
    font-size: 14px;
  }

  @include tablet {
    margin-top: 0px;
    margin-left: 32px;
    line-height: 16px;
    font-size: 16px;
  }

  @include desktop {
    margin-bottom: unset;
  }
}

.hero__freePeriod {
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 32px;
  color: var(--secondary-color);

  @include mobile {
    margin-bottom: 16px;
  }

  @include tablet {
    line-height: 16px;
    font-size: 14px;
  }

  @include desktop {
    margin-bottom: 32px;
  }
}

.hero__benefits {
  display: flex;
  justify-content: center;
  margin-bottom: 72px;

  @include tablet {
    justify-content: flex-start;
    margin-bottom: 90px;
  }

  @include desktop {
    justify-content: flex-start;
    margin-bottom: 85px;
  }
}

.hero__benefitsBr {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 48px;

  @include tablet {
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 66px;
  }

  @include desktop {
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 61px;
  }
}

.hero__arrow {
  display: none;
  position: absolute;

  @include desktop {
    display: block;
    left: calc(var(--base-desktop-wrapper-padding) + 424px);
    top: calc(var(--base-header-desktop-height) + 50px);
    width: 187px;
  }

  @include big-desktop {
    left: calc(var(--base-desktop-wrapper-padding) + 552px);
    top: calc(var(--base-header-desktop-height) + 80px);
    width: 250px;
  }
}

.hero__brands {
  padding-bottom: 40px;

  @include tablet {
    padding-bottom: 38px;
  }

  @include desktop {
    padding-bottom: 47px;
    margin-top: 90px;
    align-self: flex-end;
  }

  @include big-desktop {
    padding-bottom: 40px;
  }

  &_autoposting,
  &_autoposting-vk {
    margin-top: 200px;

    @include mobile {
      div {
        overflow: hidden;
      }

      width: 400px;
      margin-left: -38px;
      margin-top: 20px;
      scale: 0.8;
    }

    @include large-mobile {
      div {
        overflow: hidden;
      }

      width: 400px;
      margin-left: -20px;
      margin-top: 20px;
      scale: 0.8;
    }

    @include small-tablet {
      width: 640px;
      margin-left: -50px;
      margin-top: 120px;
      scale: 0.8;
    }

    @include tablet {
      width: 760px;
      margin-left: -60px;
      margin-top: 120px;
      scale: 0.8;
    }

    @include large-tablet {
      width: 800px;
      margin-left: 100px;
      margin-top: 170px;
      scale: 0.8;
    }

    @include desktop {
      margin-top: 220px;
      width: inherit;
      margin-left: inherit;
      scale: 1;

      ul {
        margin-top: 10px;
        scale: 0.7;
      }
    }

    @include big-desktop {
      ul {
        scale: 0.8;
      }
    }
  }
}

.showMoreBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;

  @include mobile {
    margin-bottom: 24px;
  }

  @include tablet {
    margin-bottom: 20px;
  }

  @include desktop {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 32px;
  }
}

.showMore {
  margin-right: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: var(--gray-500, #a6a9b5);
  cursor: pointer;
  z-index: 1;
}

.analysis {
  max-width: 517px;
  margin: 48px 0 0;
}